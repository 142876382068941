import React, { useState } from 'react';
import "./NavigationBar.css";

interface NavItem {
  name: string;
  url: string;
}

interface NavigationBarProps {
  items: NavItem[];
}

const NavigationBar: React.FC<NavigationBarProps> = ({ items }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const renderNavItems = () => {
    return items.map((item, index) => (
      <a key={index} href={item.url} className="nav-button">
        {item.name}
      </a>
    ));
  };

  return (
    <div>
      <nav className="navigation-bar">
        <img
          className="navigation-logo"
          src={"./favicon-48.png"}
          alt={"Plasius LTD Logo"}
        />
        <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          <span />
          <span />
          <span />
        </div>
        <div className={`nav-items ${isMobileMenuOpen ? "active" : ""}`}>
          {renderNavItems()}
        </div>
        {isMobileMenuOpen && (
          <div className="mobile-nav">{renderNavItems()}</div>
        )}
      </nav>
    </div>
  );
};

export default NavigationBar;
