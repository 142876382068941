import React from "react";
import "./about-page.css"; // Import the CSS file for styling

const AboutPage: React.FC = () => {
  return (
    <div>
      <img
        src={process.env.PUBLIC_URL + "/PlasiusLTDLogo.png"}
        alt="Plasius LTD Logo"
        className="responsive-image"
      />
      <div className="about-page">
        <h1>About Us</h1>
        <p>
          Welcome to <a href="plasius.co.uk">plasius.co.uk</a>
        </p>
        <h2>Our Mission</h2>
        <p>
          To create awesome applications and web sites that are a delight for
          all
        </p>
        <h2>Our Team</h2>
        <ul>
          <li>Phillip Hounslow - Director of Software</li>
          <li>Sarah Hounslow - Director of Finance</li>
          <li>Jasmine Hounslow - Tester</li>
          <li>Alexander Hounslow - Tester</li>
          <li>Joanne Robinson - Game development</li>
        </ul>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or feedback, please don't hesitate to reach
          out to us.
        </p>
        <p>
          Email: <a href="mailto:web@plasius.co.uk">web@plasius.co.uk</a>
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
