import React from "react";
import "./app.css";

import UnderConstructionPage from "./under-construction";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import AboutPage from "./about-page";
import Hexagons from "./hexagons";

function App() {
  const navItems = [
    { name: "Home", url: "/" },
    { name: "About", url: "/about" },
    { name: "Hexagons", url: "/hexagons" },
    // Add more navigation items here
  ];

  return (
    <div className="App">
      <NavigationBar items={navItems} />
      <div id="root">
        <Router>
          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/hexagons" element={<Hexagons />} />
            <Route path="/" element={<UnderConstructionPage />} />
            {/* Add more routes for other pages */}
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
