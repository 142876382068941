import React from 'react';

import "./under-construction.css";

const UnderConstructionPage: React.FC = () => {
  return (
    <div className="under-construction">
      <div className="innerstylediv">
        <div>
          <img
            src={process.env.PUBLIC_URL + "/PlasiusLTDLogo.png"}
            alt="Plasius LTD Logo"
            className="responsive-image"
          />
        </div>
        <h1>Page Under Construction</h1>
        <p>We're working on something awesome. Please check back later!</p>
      </div>
    </div>
  );
};

export default UnderConstructionPage;
